import * as React from 'react';
import { Helmet } from 'react-helmet';
import FeudleArchive from '../../components/Feudle/archive';
import Feudle, { FeudleType } from '../../components/Feudle/index';
import Logo from '../../components/Logo';

const FeudlePage = () => {
	const [feudle, setFeudle] = React.useState<FeudleType | undefined>();

	return (
		<>
			<Helmet>
				<title>Google Feudle</title>
			</Helmet>
			<Logo isFeudle />
			<div style={{ marginBottom: '10rem' }}>
				<Feudle load={feudle} />
			</div>
			<FeudleArchive link setFeudle={setFeudle} />
		</>
	);
};
export default FeudlePage;
